.portfolio-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: #01FF7F;
  text-transform: uppercase;
  text-decoration: none;
  border: 2px solid #01FF7F;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  background-color: transparent;
}

.portfolio-button:hover {
  background-color: #01FF7F;
  color: #000;
}

.tagcloud-wrap {
  // background: gray;
  float: right;
  width: 53%;
  height: 115%;
  margin-top: -3%;

  .tagcloud {
    color: #ff4500;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 650;
    margin: 10% auto;
  }
  .tagcloud--item:hover {
    color: #00ff7f;
  }
}
