.contact-form {
  width: 100%; // Set to 100% to take the full width of the parent container
  margin-top: 20px;
  margin-left: 0; // Remove any additional margin-left

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      border-radius: 0.5rem;
      list-style: none;
      margin-bottom: 10px;
      opacity: 0;
      overflow: hidden;
      display: block;
      clear: both;
      position: relative;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
    }

    li.half {
      width: 49%;
      margin-left: 2%;
      float: left;
      clear: none;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 0;
    background: #353535;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
      font-size: 14px; // Reduce font size for tablets
      height: 45px; // Adjust height for smaller screens
    }

    @media screen and (max-width: 480px) {
      font-size: 12px; // Further reduce font size for mobile
      height: 40px; // Further adjust height for mobile
    }
  }

  textarea {
    width: 100%;
    border: 0;
    background: #353535;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;

    @media screen and (max-width: 768px) {
      font-size: 14px; // Reduce font size for tablets
      padding: 15px; // Adjust padding for smaller screens
    }

    @media screen and (max-width: 480px) {
      font-size: 12px; // Further reduce font size for mobile
      padding: 10px; // Further adjust padding for mobile
    }
  }

  .flat-button {
    color: #ff4500;
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid #ff4500;
    border-radius: 4px;
    background: 0 0;
    text-transform: uppercase;
    text-align: center;
    margin-top: 10px;
    float: right;

    &:hover {
      cursor: pointer;
      background-color: #ff4500;
      color: #000;
    }

    @media screen and (max-width: 768px) {
      font-size: 10px; // Slightly reduce font size for tablets
      padding: 7px 9px; // Adjust padding for smaller screens
    }

    @media screen and (max-width: 480px) {
      font-size: 9px; // Further reduce font size for mobile
      padding: 6px 8px; // Further adjust padding for mobile
    }
  }
}

.map-wrap {
  background: rgba(8, 253, 216, 0.1);
  float: right;
  width: 100%; // Make the map wrap full width within its container
  height: 115%;
  margin-top: -3%;
  margin-left: 0; // Remove any additional margin

  @media screen and (max-width: 768px) {
    width: 100%; // Ensure full width on tablets
    margin-left: 0; // Ensure no left margin
  }

  @media screen and (max-width: 480px) {
    width: 100%; // Ensure full width on mobile
    margin-left: 0; // Ensure no left margin
  }
}
